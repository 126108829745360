<template>
  <div class="404">
    <NF />
  </div>
</template>

<script>
// @ is an alias to /src
import NF from '@/components/NotFound.vue';

export default {
  name: 'NotFound',
  components: {
    NF,
  },
};
</script>
