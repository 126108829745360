<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Russo+One&display=swap');

#app {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  height: 100%;
}

body {
    min-height: 100%!important;
    background: #282c34!important;
    font-family: 'Lato', sans-serif!important;
    font-weight: 400!important;
    color: #565555!important;
    font-size: 16px!important;
    line-height: 26px!important;
    padding-bottom: 50px!important;
}

.cv_container {
    background: #282c34;
    margin: 50px auto 0px!important;
    box-shadow: 1px 1px 2px #DAD7D7;
    border-radius: 3px;
    padding: 40px;
    color: #dee2e6!important;
}

.full-name {
    font-size: 40px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.first-name {
font-weight: 700;
}

.last-name {
font-weight: 300;
}

.contact-info {
margin-bottom: 20px;
}

.email ,
.phone ,
.local ,
.linkedin {
color: #999;
font-weight: 500;
}

.position {
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
    text-decoration: underline;
}

.details {line-height: 20px;}

.experience {
    margin-bottom: 40px;
}

.experience:last-of-type {
    margin-bottom: 0px;
}

.contact-info,
.about-me,
.experience__title,
.education__title,
.skills__title,
.stack__title{
    letter-spacing: 2px;
    color: #54AFE4;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.name {
    font-weight: bold;
}

a {
    text-decoration: none;
    color: #000;
}

a:hover {
    text-decoration: underline;
    color: #000;
}

.list-group-flush {
    color: #dee2e6!important;
}

.list-group li {
    background: #282c34!important;
    color: #dee2e6!important;
}

.skills__item input {
    display: none;
}

label {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #C3DEF3;
    border-radius: 20px;
    margin-right: 3px;
}

.skills__item input:checked + label {
    background: #79A9CE;
}
</style>
