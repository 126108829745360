<template>
    <div class="page_not_found">
        <section class="py-3 py-md-5 min-vh-100 d-flex justify-content-center align-items-center">
            <div class="container">
                <div class="img"></div>
                <div class="row">
                    <div class="text-center">
                        <h3 class="h2 mb-2">Oops! You're lost.</h3>
                        <p class="mb-5">The page you are looking for does not exist.</p>
                        <a class="btn btn-dark rounded-pill px-5 fs-6 m-0" href="/" role="button">
                            Back to Home
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
  name: 'NotFound',
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #ffffff;
}
.img {
    background: url('/src/assets/404.png');
    height: 400px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.page_not_found {
    color: #000;
}
</style>
