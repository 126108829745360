<template>
  <div class="home">
    <CVP />
  </div>
</template>

<script>
// @ is an alias to /src
import CVP from '@/components/CV.vue';

export default {
  name: 'CV',
  components: {
    CVP,
  },
};
</script>
